import React from 'react'
import { Link } from 'gatsby'
import styles from './Header.module.css'
import logo from '../../assets/logo.png'
import ReactTooltip from 'react-tooltip'

class Header extends React.Component {
  state = {
    loaded: false,
  }

  componentDidMount() {
    this.setState({
      loaded: true,
    })
  }

  renderPopupMenu() {
    return (
      <ReactTooltip
        id="product-link"
        className={styles.productTooltip}
        effect="solid"
        delayHide={100}
        delayUpdate={500}
      >
        <div className={styles.productMenu}>
          <span>Explore product features</span>
          <ul>
            <li>
              <Link to={'/learn/feedback'}>Collect feedback</Link>
            </li>
            <li>
              <Link to={'/learn/roadmap'}>Public roadmap</Link>
            </li>
            <li>
              <Link to={'/learn/changelog'}>Product changelog</Link>
            </li>
          </ul>
        </div>
      </ReactTooltip>
    )
  }

  renderDropdown = () => {
    return (
      <React.Fragment>
        <div className="dropdown dropdown-right">
          <a href="#menu" className="btn btn-link dropdown-toggle" tabIndex="0">
            <i className="fas fa-bars" /> <i className="fas fa-caret-down"></i>
          </a>
          <ul className="menu">
            <li>
              <Link className="btn btn-link" to={'/'}>
                Home
              </Link>
            </li>
            <li>
              <Link className="btn btn-link" to={'/pricing'}>
                Pricing
              </Link>
            </li>
            <li>
              <Link className="btn btn-link" to={'/blog'}>
                Blog
              </Link>
            </li>
            <li>
              <a className="btn btn-link" href="https://app.suggested.co">
                Login
              </a>
            </li>
            <li>
              <a
                className="btn btn-link"
                href="https://app.suggested.co/register"
              >
                Signup
              </a>
            </li>
          </ul>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <header className="navbar">
            <section className="navbar-section">
              <Link className="navbar-brand" to={'/'}>
                <img className={styles.logo} src={logo} alt="Logo" />
              </Link>
            </section>

            <section className="navbar-section hide-sm">
              <Link className="btn btn-link" to={'/'}>
                Home
              </Link>
              <a
                className="btn btn-link"
                href="#product"
                data-tip
                data-for="product-link"
                data-place="bottom"
              >
                Product
              </a>
              <Link className="btn btn-link" to={'/pricing'}>
                Pricing
              </Link>
              <Link className="btn btn-link" to={'/blog'}>
                Blog
              </Link>
              <a className="btn btn-link" href="https://changelog.suggested.co">
                What's new <i className="fas fa-fire" />
              </a>
              <a className="btn btn-link" href="https://app.suggested.co">
                Login
              </a>
            </section>
          </header>
          {this.state.loaded ? this.renderPopupMenu() : null}
        </div>
      </div>
    )
  }
}

export default Header
