import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Intercom from '../components/Intercom'
import RebrandNotice from "../components/RebrandNotice"

import icon32 from '../../assets/favicon.png'
import socialImage from '../../assets/social.png'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            gitVersion
            baseUrl
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
            {
              name: 'keywords',
              content: 'Customer feedback, public roadmap, product changelog',
            },
            { property: 'og:title', content: data.site.siteMetadata.title },
            {
              property: 'og:descripion',
              content: data.site.siteMetadata.description,
            },
            {
              property: 'og:image',
              content: data.site.siteMetadata.baseUrl + socialImage,
            },
            {
              name: 'twitter:title',
              content: 'Customer feedback management made easy',
            },
            { name: 'twitter:card', content: 'summary_large_image' },
            {
              name: 'twitter:image',
              content: data.site.siteMetadata.baseUrl + socialImage,
            },
            {
              name: 'twitter:description',
              content:
                'Collect feedback from your customers, surface the most wanted features and build products loved by your customers',
            },
            {
              name: 'ahrefs-site-verification',
              content:
                '3858d5a14797fcc2ab4f7c953c198447a912c6a95084c4ddcf6856cbce93ba4e',
            },
          ]}
          link={[
            { rel: 'icon', type: 'image/png', href: icon32, sizes: '32x32' },
          ]}
          script={[{ src: 'https://kit.fontawesome.com/2026537813.js' }]}
        ></Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
          {children}
        <Footer version={data.site.siteMetadata.gitVersion} />
        <Intercom />
      </>
    )}
  />
)

export default Layout
