import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Root = styled.div`
  padding: 0.25rem;
  background-color: #d9e4f9;
  font-weight: 500;
  text-align: center;

  a {
  }
`

const RebrandNotice = () => (
  <Root>
    Makerkit is now called Suggested. <span role="img">🎉</span> <Link to={`/blog/makerkit-rebrands-to-suggested`}>Read the announcement</Link>
  </Root>
)

export default RebrandNotice
