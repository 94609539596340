import React from 'react'

export default class Intercom extends React.Component {
  componentDidMount() {
    const code = this.getJavascriptCode()
    this.insertScript(code)
  }

  insertScript(scriptText) {
    const script = document.createElement('script')
    script.innerHTML = scriptText
    script.async = true
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  getJavascriptCode() {
    const scriptInner = `
      window.intercomSettings = {
        app_id: "n6w9jp5u"
      };
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/n6w9jp5u';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    `

    return scriptInner
  }

  render() {
    return null
  }
}
